import * as React from "react";
import { Tooltip } from "../Tooltip";
import chatbotImage from "./assets/chatbot.svg";

export const ChatBotButton = React.memo(() => {
  return (
    <Tooltip label={"Bot chat"}>
      <a
        target="_blank"
        href={process.env.REACT_APP_BOT_CHAT}
        className="text-white "
      >
        <img src={chatbotImage} alt="" className="w-4 h-4" />
      </a>
    </Tooltip>
  );
});
